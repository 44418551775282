import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import { Helmet } from "react-helmet";
import { blogAuthorTranslations } from "../../translations/blog";

import { getLangPrefixedPath } from "@v4/utils/pathPrefix";

// Styling
import * as blogStyles from "./blogAuthorPage.module.css";
import classNames from "classnames/bind";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Clamp from "react-multiline-clamp";
import Link from "@v4/gatsby-theme-talend/src/components/Link";

export const query = graphql`
    query($locale: String = "en", $authorId: String) {
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
        blogs: allContentfulBlog(
            filter: { author: { elemMatch: { id: { eq: $authorId } } }, node_locale: { eq: $locale } }
            sort: { fields: publishDate, order: DESC }
            limit: 15
        ) {
            nodes {
                id
                slug
                title
                node_locale
            }
        }
        author: contentfulBlogAuthor(id: { eq: $authorId }, node_locale: { eq: $locale }) {
            title
            slug
            details {
                raw
            }
            image {
                # fluid (maxWidth: 900, transformations: "c_fit")
                public_id
                width
                height
                format
            }
            metaFields {
                metaTitle
                metaDescription
            }
            createdAt
            updatedAt
            node_locale
            imageAltText
        }
    }
`;

export default function BlogAuthorPage({ data, pageContext }) {
    const { locale } = pageContext;
    const lang = locale;
    const author = data.author;
    const blogs = data.blogs.nodes;

    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    const styleProps = {};
    const cx = classNames.bind(blogStyles);

    // Fallback author image
    const defaultAuthorImage = {
        format: "png",
        public_id: "blog/author-headshots/blog-author-default_uvuw1n",
        width: 778,
        height: 778,
    };

    // check if there is a blog image available
    const authorImage = author && author.image ? author.image[0] : defaultAuthorImage;

    const authorImageStyle = authorImage
        ? {
              maxWidth: "350px",
              width: "100%",
              height: "auto",
          }
        : null;
    const authorImageAltText = author.imageAltText ?? "Generic line art headshot with a blue background";

    // Set up breadcrumbs object
    let crumbList = {
        Blog: "/blog",
        [author.title]: null,
    };

    return (
        <Layout
            headerMenu={data.contentfulMenu}
            footerMenu={footerData}
            notificationBar={false}
            pageStyleProps={styleProps}
        >
            {author.metaFields && (
                <SEO
                    title={author.metaFields?.metaTitle ?? author.title}
                    pathname={getLangPrefixedPath(`/blog/${author.slug}`, author.node_locale)}
                    description={author.metaFields?.metaDescription ?? ""}
                    modifiedTime={author.updatedAt}
                    createdTime={author.createdAt}
                    languages={["English"]}
                    type={`BlogPost`}
                />
            )}
            <Helmet bodyAttributes={{ class: "darkOpaque" }} />
            <div className={cx("heroBg")} />
            <section className={cx("blogAuthorGrid")}>
                <div className={cx("contentWrapper")}>
                    <h1 className={cx("blogTitle")}>{author.title}</h1>
                    <Breadcrumbs crumbList={crumbList} />
                    <div className={cx("blogContent")}>
                        {author.details?.raw && renderRichText(author.details, formatContentfulRichText())}
                    </div>
                </div>
                <div className={cx("imageWrapper")}>
                    {authorImage ? (
                        <Image
                            className={cx("blogEntryImage")}
                            image={authorImage}
                            style={authorImageStyle}
                            imgStyle={{ objectFit: "contain" }}
                            alt={authorImageAltText}
                        />
                    ) : (
                        <div className={cx("placeholderLogo")} />
                    )}
                </div>
            </section>
            <section className={cx("moreBlogsWrapper")}>
                <h5 className={cx("blogListTitle")}>{blogAuthorTranslations[lang].moreByAuthor}</h5>
                <ul className={cx("moreBlogs")}>
                    {blogs.map((blog) => (
                        <li key={blog.id}>
                            <Link
                                to={getLangPrefixedPath(`/blog/${blog.slug}`, blog.node_locale)}
                                className={cx("blogLink")}
                            >
                                <Clamp lines="2" withTooltip={false}>
                                    {blog.title}
                                </Clamp>
                            </Link>
                        </li>
                    ))}
                </ul>
            </section>
        </Layout>
    );
}
